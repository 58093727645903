#login-page,
#info-page {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.login-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.login-page-logo {
  height: 120px;
  width: 120px;
  margin-inline: auto;
  margin-top: 104px;
  margin-bottom: 84px;
}

.login-input-group {
  padding-inline: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.login-input {
  height: 48px;
  margin-bottom: 12px;
  outline: none;
  border: 1px solid #e9ecef;
  padding-inline: 12px;
  border-radius: 100rem;
  width: 100%;
}

.login-input:hover,
.login-input:focus {
  outline: none;
  border-color: #02c39a;
}

.login-button {
  background-color: #02c39a;
  padding-inline: 16px;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 100rem;
  height: 48px;
  color: #fff;
  margin-bottom: 12px;
}

.register-group {
  display: flex;
  justify-content: space-between;
}

.register-link {
  border-radius: 8px;
  text-decoration: none;
  text-align: end;
  color: #02c39a;
}

.login-footer {
  margin: 32px 0;
  text-align: center;
}

.pin-link,
.pin-setting {
  border: none;
  outline: none;
  background-color: transparent;
  color: #02c39a;
}

.pin-link:disabled,
.pin-link[disabled] {
  color: #ccc;
}

.pin-setting {
  display: flex;
  align-items: center;
}

.arrow-right {
  margin-left: 8px;
}

.arrow-left {
  margin-right: 8px;
}

#info-page > .login-input-group {
  padding-top: 32px;
  padding-bottom: 32px;
  align-items: center;
  flex: 1;
  justify-content: center;
}

.info-header {
  font-size: 24px;
  font-family: Opensans-bold;
  margin-bottom: 24px;
}

.pin-display {
  display: flex;
}

.pin-item {
  height: 48px;
  width: 48px;
  border-radius: 8px;
  border: 1px solid #e9ecef;
  margin-inline: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pin-item.fill {
  border-color: #02c39a;
}

.pin-number {
  margin-top: 20px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: auto auto auto;
}

.delete-number {
  border-color: transparent !important;
}

.number-item {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 1px solid #e9ecef;
  margin: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
}

.checkbox-item {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 12px;
  align-items: center;
}

#checkbox {
  margin-right: 8px;
}

.button-group {
  padding: 16px;
}

.second-button {
  border: 1px solid #02c39a;
  color: #02c39a;
  background-color: #fff;
}
