.default-layout {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.layout-content {
  flex: 1;
  overflow: auto;
}

.layout-container {
  flex: 1;
  overflow: auto;
}

@media (min-width: 768px) {
  .layout-content {
    max-width: 720px;
    margin-inline: auto;
  }
}
