#select-category {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

#select-category .frm-header {
  height: 40px;
}

.frm-title {
  font-size: 20px;
  font-family: Opensans-bold;
}

.search-wrap {
  position: relative;
}

.search-bar {
  height: 40px;
  outline: none;
  border: none;
  padding-inline: 36px 12px;
  border-radius: 100rem;
  width: 100%;
  background-color: #e9ecef;
}

.search-bar:hover,
.search-bar:focus {
  outline: none;
}

.search-icon {
  position: absolute;
  left: 8px;
  color: #6c757d;
  font-size: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.category-item {
  display: flex;
  width: 100%;
  padding: 8px;
  border-radius: 16px;
  background-color: #e9ecef;
  align-items: center;
}

.category-item img {
  width: 48px;
  height: 48px;
  margin-right: 12px;
}

.lst-category {
  flex: 1;
  overflow: auto;
}
