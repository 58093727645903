#sidebar {
  width: 250px;
  border-right: 1px solid #e9ecef;
  height: 100%;
}

.sidebar-header a {
  color: #000;
  text-decoration: none;
}

.sidebar-header span {
  font-family: OpenSans-semibold;
  font-size: 20px;
}

.side-link {
  width: 100%;
  align-items: center;
  display: flex;
  color: #6c757d;
  text-decoration: none;
  border-radius: 8px;
  cursor: pointer;
}

.side-link:hover {
  background-color: #f8f9fa;
  color: #6c757d;
}

.side-link.active,
.side-link.active:hover {
  color: #02c39a;
  background-color: rgb(2, 195, 154, 0.05);
  font-family: OpenSans-semibold;
}

.side-link > svg {
  font-size: 20px;
}

.side-link > span {
  font-size: 14px;
}
