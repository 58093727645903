.select-filter-date-report {
  width: 100%;
  background-color: #fff;
  color: #000;
  border: none;
  outline: none;
  border-radius: 0;
  height: 48px;
}

.report-line-chart {
  background-color: #fff;
}

.spending-detail {
  border-radius: 16px;
  background-color: #f8f9fa;
}

.date-circle {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  font-family: OpenSans-semibold;
}

.day-detail {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.report-total-amount {
  border-radius: 16px;
  background-color: #f8f9fa;
}

.report-total-amount span:nth-child(1) {
  color: #6c757d;
}

.report-content {
  overflow: auto;
  position: relative;
  flex: 1;
  background-color: #fff;
}

.spending-detail-item {
  background-color: #fff;
  border-bottom: 1px solid #e9ecef;
}
