#report-revenue-expenditure {
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

#report-revenue-expenditure .frm-header {
  background-color: #fff;
}

.picker-wrapper {
  background-color: #fff;
}

.pick-year {
  border: none;
  outline: none;
}

.chart-revenue {
  background-color: #fff;
}

.month-detail {
  background: #f8f9fa;
  border-radius: 16px;
  cursor: pointer;
}

.month-name {
  font-size: 18px;
  font-family: OpenSans-semibold;
}

.month-desc {
  font-size: 14px;
  color: #6c757d;
  font-style: italic;
}

.surplus-amount {
  border-top: 1px solid #f1f1f1;
  width: 100%;
  text-align: end;
  font-family: OpenSans-semibold;
}
