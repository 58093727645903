#report-item {
  border-radius: 16px;
  background-color: #f8f9fa;
  height: 148px;
}

.report-icon svg {
  font-size: 24px;
}

.report-icon {
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.report-name {
  font-size: 15px;
  text-align: center;
}
