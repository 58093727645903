#debt-page {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.debt-content {
  flex: 1;
  overflow: auto;
}

.tab-control {
  width: 100%;
  font-family: Opensans-semibold;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
}

.tab-control.active {
  border-bottom: 3px solid #02c39a;
  color: #02c39a;
}

.current-debt,
.lst-debt-detail {
  background-color: #f8f9fa;
  border-radius: 16px;
}

.current-debt p,
.lst-debt-detail p {
  font-size: 18px;
  font-family: 'OpenSans-semibold';
}

.current-debt span:nth-child(1) {
  color: #6c757d;
}

.debt-date {
  border-bottom: 1px solid #e9ecef;
}
