.bottom-bar {
  align-items: center;
  justify-content: space-between;
}

.bottom-link {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  color: #6c757d;
  text-decoration: none;
}

.bottom-link.active {
  color: #02c39a;
}

.bottom-link > svg {
  font-size: 24px;
}

.bottom-link > span {
  font-size: 10px;
}
