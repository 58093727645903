#budget-page {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.tab-list {
  height: 45px;
}

.CircularProgressbar-text {
  fill: #02c39a !important;
}

.budget-title {
  font-size: 20px;
  font-family: Opensans-bold;
}

.budget-amount {
  font-size: 24px;
  color: #02c39a;
  font-family: Opensans-bold;
}
