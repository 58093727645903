.loading-bg-full {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: blur(4px);
}

.loading-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.loading {
  position: absolute;
  left: calc(50% - 40px);
  top: calc(50% - 40px);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
  z-index: 9999;
}

.loading-image {
  width: 100%;
  height: 100%;
  animation: rotate 1s ease infinite;
}
