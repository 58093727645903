#report-page {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #fff;
}

#report-item {
  cursor: pointer;
}

.report-list {
  flex: 1;
  overflow: auto;
}
