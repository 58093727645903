#loan-tab {
  flex: 1;
  background-color: #fff;
}

.loan-amount {
  background-color: #f8f9fa;
  border-radius: 16px;
}

.amount {
  font-family: Opensans-semibold;
}

.loan-bar {
  border-radius: 100rem;
  height: 8px;
  background-color: #ccc;
  overflow: hidden;
}

.loan-bar-done {
  height: 8px;
  background-color: #02c39a;
  border-radius: 100rem;
  width: 0%;
  transition: all 0.5s ease;
}

.loan-bar-err {
  background-color: #ff4d6d;
}

.object-report-name {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  color: #f8f9fa;
  font-family: Opensans-semibold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  background-color: #ccc;
}

.loan-detail {
  background-color: #f8f9fa;
  border-radius: 16px;
}

.loan-detail-title {
  cursor: pointer;
}

.loan-detail-item {
  border-top: 1px solid #e9ecef;
  cursor: pointer;
}

.object-name span {
  font-size: 18px;
  font-family: Opensans-Semibold;
}

.no-loan {
  height: 100%;
}
