#list-transaction {
  width: 100%;
  height: 100%;
  position: relative;
  /* background-color: #f8f9fa; */
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

#list-transaction .frm-header {
  background-color: #fff;
}

#list-transaction .frm-top {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 1;
}

.list-trans {
  flex: 1;
  overflow: auto;
}

.trans-item {
  background-color: #fff;
  border-bottom: 1px solid #e9ecef;
}

.trans-head {
  border-left: 6px solid #02c39a;
}

.trans-head h1 {
  font-family: OpenSans-semibold;
  margin-bottom: 0;
}

.receive-amount {
  font-family: OpenSans-semibold;
  color: #02c39a;
}

.pay-amount {
  font-family: OpenSans-semibold;
  color: #ff4d6d;
}

.trans-cate {
  font-family: Opensans-semibold;
}

.trans-desc,
.trans-desc i {
  font-size: 14px;
  color: #6c757d;
}

.select-filter-date-list {
  color: #000;
  background-color: #fff;
  border-radius: 0;
  outline: none;
  border: none;
  height: 48px;
}
