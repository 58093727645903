.finance-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.current-finances,
.current-amount,
.current-loan {
  background: #f8f9fa;
  border-radius: 16px;
}

.finance-item.header {
  height: 48px;
}

.finance-item > span {
  font-family: OpenSans-semibold;
  display: flex;
  align-items: center;
}

.finance-item .note {
  color: #6c757d;
  font-family: OpenSans-regular;
}
