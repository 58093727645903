#home-page {
  width: 100%;
  position: relative;
  background-color: #fff;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.home-header {
  background-color: rgb(2, 195, 154);
  color: #fff;
  border-radius: 16px;
  box-shadow: rgba(2, 195, 154, 0.5) 0px 5px 15px;
}

.home-welcome {
  font-size: 20px;
  font-family: Opensans-bold;
}

.home-money {
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-money span {
  color: #6c757d;
}

.home-money p {
  font-size: 28px;
  color: #02c39a;
  font-family: Opensans-bold;
  margin-bottom: 0;
}

.btn-add {
  position: fixed;
  bottom: 96px;
  right: 16px;
  border: none;
  outline: none;
  width: 48px;
  height: 48px;
  background-color: #02c39a;
  border-radius: 50%;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(2, 195, 154, 0.5) 0px 5px 15px;
}

.btn-add > svg {
  font-size: 24px;
}

.situation {
  border-radius: 16px;
  flex: 1;
  overflow: auto;
}

.situation-header {
  font-size: 18px;
  font-family: Opensans-semibold;
}

.situation-chart {
  display: flex;
}

.show-detail a {
  text-decoration: none;
  color: #02c39a;
}

.select-filter-date {
  color: #6c757d;
  background-color: #fff;
  border: none;
  outline: none;
}

.filter-datepicker {
  border: none;
  outline: none;
  width: 100px !important;
}
#home-page .react-datepicker__input-container {
  width: auto !important;
}
#home-page .react-datepicker-wrapper {
  flex: 0;
}

.gray-icon {
  color: #6c757d;
}

.home-balance {
  cursor: pointer;
}

@media (min-width: 768px) {
  .btn-add {
    bottom: 16px;
  }
}
