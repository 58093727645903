#add-transaction {
  width: 100%;
  height: 100%;
  position: relative;
}

.frm-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.frm-header svg {
  font-size: 24px;
  color: #02c39a;
}

.frm-header span {
  font-size: 20px;
  font-family: Opensans-bold;
}

.frm-trans-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.select-type {
  height: 40px;
  border-radius: 100rem;
  border: none;
  outline: none;
  color: #02c39a;
}

.frm-money-gr {
  border-bottom: 1px solid #e9ecef;
}

.frm-money-gr span {
  font-size: 20px;
}

.frm-money {
  width: 100%;
  outline: none;
  border: none;
  border-radius: 0;
  text-align: end;
  font-size: 32px;
  font-family: Opensans-bold;
  color: #ff4d6d;
  padding-inline: 8px;
}

.frm-money.receive {
  color: #02c39a;
}

.add-group {
  display: flex;
}

.group-icon {
  width: 48px;
  height: 48px;
  margin-right: 12px;
  background-color: #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.group-icon svg {
  font-size: 20px;
  color: #fff;
}

.group-item {
  flex: 1;
  border-radius: 0;
  border-inline: none;
  border-top: none;
  border-bottom: 1px solid #e9ecef !important;
  outline: none;
}

button.group-item {
  color: #6c757d;
  background-color: #fff;
  font-size: 20px;
  text-align: left;
  border: none;
  outline: none;
  padding-inline: 8px;
  font-family: Opensans-semibold;
}

button.group-item.selected {
  color: #000;
}

input.group-item {
  border: none;
  outline: none;
  padding-inline: 8px;
  background-color: #fff;
  text-align: left;
  color: #000;
  font-size: 18px;
}

div.group-item {
  border: none;
  outline: none;
  padding-inline: 8px;
  background-color: #fff;
  text-align: left;
  color: #6c757d;
  font-size: 18px;
  display: flex;
  align-items: center;
}

div.group-item.selected {
  color: #000;
}

select.group-item {
  background-color: transparent;
  font-size: 18px;
  color: #000;
}

.react-datepicker-wrapper {
  flex: 1;
}

.react-datepicker__input-container {
  height: 100%;
}

.react-datepicker__input-container input {
  height: 100%;
  width: 100%;
}

.delete-button {
  background-color: #ff4d6d;
}
