@import url('./bootstrap.css');

@font-face {
  font-family: Opensans-regular;
  src: url(../assets/fonts/OpenSans-Regular.ttf);
}

@font-face {
  font-family: Opensans-italic;
  src: url(../assets/fonts/OpenSans-Italic.ttf);
}

@font-face {
  font-family: Opensans-semibold;
  src: url(../assets/fonts/OpenSans-SemiBold.ttf);
}

@font-face {
  font-family: Opensans-bold;
  src: url(../assets/fonts/OpenSans-Bold.ttf);
}

*,
::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;
}

body {
  position: relative;
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  font-family: Opensans-regular;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6c757d;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #6c757d;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #6c757d;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.35);
}
::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.35);
}
