#debt-object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000033;
  display: flex;
  align-items: center;
  justify-content: center;
}

.debt-object-content {
  margin-inline: 36px;
  width: calc(100% - 72px);
  height: 360px;
  background-color: #fff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.object-name-circle {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  color: #fff;
  font-family: Opensans-semibold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  background-color: #ccc;
}

.list-object {
  flex: 1;
  overflow: auto;
}
