.user-info {
  border-radius: 16px;
  background-color: #f8f9fa;
}

.user-info div:first-child {
  font-size: 1.5rem;
  font-weight: 600;
}

.user-info div:last-child {
  color: #6c757d;
}

.logout-button {
  background-color: #ff4d6d;
  padding-inline: 16px;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 100rem;
  height: 48px;
  color: #fff;
}
